import axios from '@axios'

export default {
  namespaced: true,
  state: {
    merchants: [],
  },
  getters: {},
  mutations: {},
  actions: {
    fetchMerchantVirtualAccounts(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/merchant/transfer/account/list', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    fetchVirtualAccountTransactions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/merchant/transfer/account/transaction/list', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    downloadVirtualAccountTransactions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/merchant/transfer/account/transaction/download', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    fetchVirtualAccountDownloadList() {
      return new Promise((resolve, reject) => {
        axios.post('/merchant/transfer/account/transaction/download-list')
          .then(response => {
            if (response.data.code === '10000') {
              resolve(response)
            }
          })
          .catch(error => { reject(error) })
      })
    },
  },
}
